<template>
  <page>
    <template slot="headerRight">
      <el-button
        v-auth="'batch:auditYes'"
        @click="passHandler(false)"
        :disabled="!multipleSelection.length"
        type="primary"
      >
        批量通过
      </el-button>
      <el-button
        v-auth="'batch:auditNo'"
        @click="rejectHandler(false)"
        :disabled="!multipleSelection.length"
        type="primary"
      >
        批量拒绝
      </el-button>
    </template>

    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="审批类型" prop="auditType">
          <el-select clearable v-model="params.auditType">
            <el-option
              v-for="item in auditTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="审批状态" prop="auditStatus">
          <el-select clearable v-model="params.auditStatus">
            <el-option
              v-for="item in auditStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="发起人" prop="initiator">
          <el-input v-model="params.initiator" placeholder="发起人"></el-input>
        </el-form-item>
      </el-form>
    </template>

    <div class="table-wrap">
      <el-table
        @selection-change="handleSelectionChange"
        :data="tableData"
        v-loading="loading"
        height="100%"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          show-overflow-tooltip
          prop="auditOrderNo"
          label="审批单号"
        />
        <el-table-column
          show-overflow-tooltip
          prop="auditTypeStr"
          label="审批类型"
        />
        <el-table-column
          show-overflow-tooltip
          prop="amountStr"
          label="申请金额"
        />

        <el-table-column show-overflow-tooltip prop="remark" label="备注" />
        <el-table-column
          show-overflow-tooltip
          prop="initiatorName"
          label="发起人"
        />
        <el-table-column
          show-overflow-tooltip
          prop="initiationTime"
          label="发起时间"
        />
        <el-table-column
          show-overflow-tooltip
          prop="auditStatusStr"
          label="审批状态"
        />
        <el-table-column
          show-overflow-tooltip
          prop="approverName"
          label="审批人"
        />
        <el-table-column
          show-overflow-tooltip
          prop="approveTime"
          label="审批时间"
        />
        <el-table-column width="150" label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-auth="'audit:details'"
              @click="detailHandler(row)"
              type="text"
              size="mini"
              >详情</el-button
            >

            <template v-if="row.auditStatusStr == '待处理'">
              <el-button
                v-auth="'audit:auditYes'"
                @click="passHandler(row.auditOrderNo)"
                type="text"
                size="mini"
                >通过</el-button
              >

              <el-button
                v-auth="'audit:auditNo'"
                @click="rejectHandler(row.auditOrderNo)"
                type="text"
                size="mini"
                >拒绝</el-button
              >
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <DetailModal
      v-if="detailVisible"
      :visible.sync="detailVisible"
      :current-item="currentItem"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import DetailModal from './components/DetailModal.vue'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      visible: false,
      detailVisible: false,
      currentItem: null,
      tableData: [],
      auditTypeObj: {
        0: '门店还款',
        1: '余额充值',
        2: '余额扣款',
        3: '修改额度',
        4: '临时额度'
      },
      auditStatusObj: {
        0: '待处理',
        1: '审批通过',
        2: '审批不通过'
      },
      auditTypeList: [
        {
          label: '门店还款',
          value: 0
        },
        {
          label: '余额充值',
          value: 1
        },
        {
          label: '余额扣款',
          value: 2
        },
        {
          label: '修改额度',
          value: 3
        },
        {
          label: '临时额度',
          value: 4
        }
      ],
      auditStatusList: [
        {
          label: '待处理',
          value: 0
        },
        {
          label: '审批通过',
          value: 1
        },
        {
          label: '审批不通过',
          value: 2
        }
      ],
      multipleSelection: [],
      params: {
        limit: 20,
        page: 1,
        auditType: '',
        auditStatus: '',
        initiator: ''
      },
      totalCount: 0
    }
  },
  created() {
    this.getData()
  },
  components: {
    DetailModal
  },
  methods: {
    detailHandler(row) {
      this.currentItem = row
      this.detailVisible = true
    },
    async getData(query) {
      try {
        this.loading = true
        let params = { ...this.params }
        if (query) {
          params.limit = 20
          params.page = 1
        }
        const res = await this.$api.auditManage.getList(params)
        if (res.page && res.page.list) {
          this.tableData = res.page.list.map(item => {
            item.auditStatusStr = this.auditStatusObj[+item.auditStatus]
            item.auditTypeStr = this.auditTypeObj[+item.auditType]

            item.amountStr =
              item.auditType == 1
                ? `+${Number(item.amount)}`
                : item.auditType == 2
                ? `-${Number(item.amount)}`
                : Number(item.amount)

            return item
          })
        }
        this.totalCount = res.page.totalCount
      } catch (err) {
      } finally {
        this.loading = false
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val.map(_ => _.auditOrderNo)
    },

    async passHandler(auditOrderNo) {
      try {
        await this.$confirm(
          auditOrderNo ? '是否要通过该审批？' : '是否要批量通过所选审批？',
          '温馨提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )

        this.$prompt('请输入您的登录密码确认为您本人操作', '提示', {
          confirmButtonText: '确定',
          closeOnClickModal: false,
          inputPattern: /\S+/,
          inputType: 'password',
          inputErrorMessage: '请输入登录密码',
          cancelButtonText: '取消'
        })
          .then(async ({ value }) => {
            try {
              this.loading = true

              if (auditOrderNo) {
                await this.$api.auditManage.audit({
                  auditOrderNo,
                  auditStatus: 1,
                  password: value
                })
              } else {
                await this.$api.auditManage.batchAudit({
                  password: value,
                  audits: this.multipleSelection.map(No => ({
                    auditOrderNo: No,
                    auditStatus: 1
                  }))
                })
              }

              this.$message.success('通过成功')
              this.getData()
            } catch (e) {
              console.log(e)
            } finally {
              this.loading = false
            }
          })
          .catch(() => {})
      } catch (err) {
        console.log(err)
      }
    },

    async rejectHandler(auditOrderNo) {
      try {
        await this.$confirm(
          auditOrderNo ? '是否要拒绝该审批？' : '是否要批量拒绝所选审批？',
          '温馨提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )

        this.$prompt('请输入您的登录密码确认为您本人操作', '提示', {
          confirmButtonText: '确定',
          closeOnClickModal: false,
          inputPattern: /\S+/,
          inputType: 'password',
          inputErrorMessage: '请输入登录密码',
          cancelButtonText: '取消'
        })
          .then(async ({ value }) => {
            try {
              this.loading = true

              if (auditOrderNo) {
                await this.$api.auditManage.audit({
                  auditOrderNo,
                  auditStatus: 2,
                  password: value
                })
              } else {
                await this.$api.auditManage.batchAudit({
                  password: value,
                  audits: this.multipleSelection.map(No => ({
                    auditOrderNo: No,
                    auditStatus: 2
                  }))
                })
              }
              this.$message.success('拒绝成功')
              this.getData()
            } catch (e) {
              console.log(e)
            } finally {
              this.loading = false
            }
          })
          .catch(() => {})
      } catch (err) {
        console.log(err)
      }
    },

    async editHandler(row) {
      this.currentItem = row
      this.detailVisible = true
    }
  }
}
</script>

<style scoped lang="scss"></style>
