<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="详情"
    :visible.sync="show"
    width="500px"
  >
    <div v-loading="loading" class="content">
      <el-form label-width="100px" size="mini" ref="ruleForm">
        <el-form-item v-if="!tableData.length" label="门店名称：">
          <span>{{ storesName }}</span>
        </el-form-item>
        <el-form-item label="操作类型：">
          <span>{{ auditTypeObj[+targetItem.auditType] }}</span>
        </el-form-item>
        <el-form-item v-if="!tableData.length" label="交易金额：">
          <span>￥{{ amount || 0 }}</span>
        </el-form-item>
        <el-form-item label="发起人：">
          <span>{{ targetItem.initiatorName }}</span>
        </el-form-item>
        <el-form-item label="申请时间：">
          <span>{{ targetItem.initiationTime }}</span>
        </el-form-item>
      </el-form>

      <div v-if="tableData.length">
        <el-table :data="tableData" :max-height="400" border>
          <el-table-column prop="storesId" label="门店编号" />
          <el-table-column prop="storesName" label="门店名称" />
          <el-table-column prop="amount" label="充值金额" />
        </el-table>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      tableData: [],
      loading: false,
      storesName: '',
      amount: '',
      auditTypeObj: {
        0: '门店还款',
        1: '余额充值',
        2: '余额扣款',
        3: '修改额度',
        4: '临时额度'
      },
      targetItem: {
        auditType: '',
        initiatorName: '',
        initiationTime: ''
      }
    }
  },

  created() {
    this.getDetail()
  },

  methods: {
    async getDetail() {
      this.loading = true
      try {
        const res = await this.$api.auditManage.getDetail({
          auditOrderNo: this.currentItem.auditOrderNo
        })

        if (res.audit) {
          Object.keys(this.targetItem).forEach(key => {
            this.targetItem[key] = res.audit[key]
          })
        }

        if (res.memberBalanceOrder) {
          const obj = res.memberBalanceOrder

          this.amount =
            obj.type == 4
              ? obj.tempLimit
              : obj.type == 3
              ? obj.maxLimit
              : obj.amount
        }

        if (res.store) {
          this.storesName = res.store.storesName
        }

        if (res.balanceBatchOrders) {
          this.tableData = res.balanceBatchOrders
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
